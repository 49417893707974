import { React, useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Button, Badge, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input } from "reactstrap";
import {Helmet} from "react-helmet";
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Iframe from 'react-iframe';
import * as EmailValidator from 'email-validator';


import { ToastContainer } from 'react-toastify';
import { notifyError } from '../utils/toasts';

import api from '../services/api';

import '../../src/RotaDigital.css';

import { validate } from 'gerador-validador-cpf';
import { Script } from 'react-inline-script';

const RotaDigitalProva = () => {

  const [perguntas, setPerguntas] = useState([]);
  const [respostas, setRespostas] = useState([]);
  const [prova, setProva] = useState('');
  const [isAluno, setIsAluno] = useState(true);
  const [respostaAluno, setRespostaAluno] = useState([]);
  const [aluno, setAluno] = useState({});
  const [isProvaRespondida, setIsProvaRespondida] = useState(false);
  const [expert, setExpert] = useState(0);
  const [coprodutor, setCoprodutor] = useState(0);
  const [lancador, setLancador] = useState(0);
  const [perfilSelecionado, setPerfilSelecionado] = useState('Carregando...');
  const [perfilSelecionado2, setPerfilSelecionado2] = useState('Carregando...');
  const [perfilSelecionado3, setPerfilSelecionado3] = useState('Carregando...');
  const [paginas, setPaginas] = useState([436,437,438,439,440,441,442,443,444,445,446,447,448,449,450,451,452,453,454,455,456]);
  const [perguntaAtual, setPerguntaAtual] = useState(436);
  const [resultado, setResultado] = useState({});

  

  const queryParameters = new URLSearchParams( document.location.search);


  const nome = 'lcd3';//queryParameters.get("name_quiz");
  const email = 'lcd3@lcd3.com';//queryParameters.get("email_quiz");
  const telefone = '1199999999';//queryParameters.get("phone_quiz");
  const [cpf, setCpf] = useState('482.089.280-03');

  const getProva = async () => {
    try {
      const { data } = await api.get(`/prova/35`);
      const { perguntas, respostas, prova } = data.provaAtual;
      setPerguntas(perguntas);
      setRespostas(respostas);
      setProva(prova);
      exibirPergunta(436);
    } catch (error) {
    }
  };

  const salvarResposta = async (event) => {
    try {
      const resposta = {
        perguntaId : event.target.name,
        respostaId : event.target.value 
      };

      gravarAlternativa(resposta.perguntaId, resposta.respostaId);

      setRespostaAluno(respostaAluno => [...respostaAluno, resposta]);
      exibirProximaPergunta();
    } catch (error) {
      console.log(error);
    }
  };

  const exibirProximaPergunta = () => {
    if (perguntaAtual === 456) {
      enviarResposta();
      return;
    }

    if (perguntaAtual === 0) {
      exibirPergunta(247);
      setPerguntaAtual(247);
      return;
    }

    if (perguntaAtual === 170) {
      exibirPergunta(perguntaAtual + 2);
      setPerguntaAtual(perguntaAtual + 2);
      esconderPergunta(perguntaAtual);
      return;
    }

    if (perguntaAtual >= 436) {
      exibirPergunta(perguntaAtual + 1);
      setPerguntaAtual(perguntaAtual + 1);
      esconderPergunta(perguntaAtual);
      return;
    }
  }

  const exibirPergunta = (id) => {
    console.log('exibir pergunta', id);
    document.getElementById(`pergunta-${id}`).style.display = 'block';
  }

  const esconderPergunta = (id) => {
    console.log('esconder pergunta', id);
    document.getElementById(`pergunta-${id}`).style.display = 'none';
  }

  const enviarResposta = async (event) => {
    try {
      // if((expert + lancador + coprodutor) <= 8) {
      //   notifyError('Por favor responda todas as perguntas.');
      //   return false;
      // }

      // event.preventDefault();
      setIsProvaRespondida(true);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
     const retorno = await api.get(`/resultado/rotadigital/${aluno.id}`, );
     setResultado(retorno);
     setPerfilSelecionado(retorno.data[0].categoria)
     setPerfilSelecionado2(retorno.data[1].categoria)
     setPerfilSelecionado3(retorno.data[2].categoria)
     var novaImg = document.createElement('img');
     novaImg.setAttribute(
      'src',
      'https://www.facebook.com/tr?id=608209829624734&ev=Profissao&cd[primaria]='+retorno.data[0].categoria+'&cd[secundaria]='+retorno.data[1].categoria+'&cd[terciaria]='+retorno.data[2].categoria+'&noscript=1',
    );
    novaImg.setAttribute('height', 1); 
    novaImg.setAttribute('width', 1); 
    document.body.appendChild(novaImg);
    if(retorno.data[0].categoria=='INFLUENCIA'){
      window.location='https://pablomarcal.com.br/quiz-trilha-influencia/'+document.location.search;
    }
    if(retorno.data[0].categoria=='ARTE'){
      window.location='https://pablomarcal.com.br/quiz-trilha-arte/'+document.location.search;
    }
    if(retorno.data[0].categoria=='TECNOLOGIA'){
      window.location='https://pablomarcal.com.br/quiz-trilha-tecnologia/'+document.location.search;
    }
    if(retorno.data[0].categoria=='NEGOCIOS'){
      window.location='https://pablomarcal.com.br/quiz-trilha-negocio/'+document.location.search;
    }
    if(retorno.data[0].categoria=='VENDAS'){
      window.location='https://pablomarcal.com.br/quiz-trilha-vendas/'+document.location.search;
    }
    if(retorno.data[0].categoria=='CONTEUDO'){
      window.location='https://pablomarcal.com.br/quiz-trilha-conteudo/'+document.location.search;
    }
    if(retorno.data[0].categoria=='LANCAMENTOS'){
      window.location='https://pablomarcal.com.br/quiz-trilha-lancamento/'+document.location.search;
    }
    

    } catch (error) {
      console.log(error);
    }
  };

  const gravarAlternativa = async (perguntaId, respostaId) => {
    try {
      const objeto = {
        participantes_id : aluno.id,
        participantes_competicoes_id : prova.competicoes_id,
        respostas_id: respostaId,
        perguntas_id: perguntaId,
        provas_id: perguntas[0].provas_id
      }

      console.log(objeto);

      await api.post(`/participante/respostas`, objeto);
    } catch (error) {
      console.log(error);
    }
  }

  const abrirLink = () => {
    window.open("https://devzapp.com.br/api-engennier/campanha/api/redirect/66299a614d2d8a0001b103d3");
  }

  // const gravarNome = async (event) => {
  //   const nomeFormulario = event.target.value;
  //   setNome(nomeFormulario);
  // }

  // const gravarEmail = async (event) => {
  //   const emailFormulario = event.target.value;
  //   setEmail(emailFormulario);
  // }

  // const gravarTelefone = async (event) => {
  //   const telefoneFormulario = event.target.value;
  //   setTelefone(telefoneFormulario);
  // }

  const gravarCPF = async (event) => {
    const cpfFormulario = event.target.value;
    setCpf(cpfFormulario);
  }

  const cadastrarParticipante = async () => {
    console.log(nome, email, telefone, cpf);

    // if (!nome) {
    //   notifyError('O nome é obrigatório');
    //   return;
    // }

    if (!email) {
      notifyError('O email é obrigatório');
      return;
    }

    if (!EmailValidator.validate(email)) {
      notifyError('Digite um email válido');
      return;
    }

    // if (!telefone) {
    //   notifyError('O telefone é obrigatório');
    //   return;
    // }

    if (!cpf) {
      notifyError('O cpf é obrigatório');
      return;
    }

    if (!validate(cpf)) {
      notifyError('CPF inválido');
      return;
    }

    try {
      await api.post(`/rotadigital/participante`, {
        email,
        nome,
        telefone,
        cpf
      }).then((res) => {
        getProva();
        const { id } = res.data;
        setAluno({id});
        setIsAluno(false);
      });
    } catch (error) {
      console.log(error.message, error.code);
      notifyError('Dados utilizados a prova pode ser realizada apenas uma vez!');
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
  }

  useEffect(() => {
    cadastrarParticipante();
    getProva();
    setIsAluno(false);
  }, []);

  return (
    <Container className="p-3">
      <div className="centerImg">
        <img src={"img/logo-rci.png"} alt="Rico com Internet"  />
      </div>
      {isAluno ? (
        <Jumbotron display="none" style={{ background: '#1a1a1a' }}>
          {/* <h1 className="tituloUm">
            Participe da prova, <strong> acerte 80% ou 
            mais</strong> das questões relacionadas ao evento e concorra a um &nbsp;
            <span className="italicoSublinhado">
              Macbook, um IPhone e uma vaga no LX Club.
            </span>
          </h1> */}
          {/* <br /> */}
          {/* <h1 className="center tituloUm">
           Está perdido e não sabe como construir uma Renda Digital? 
          </h1>
          <br />
          <h1 className="center tituloDois">
             Deixe seus dados abaixo, faça o Quiz gratuitamente e <br /> descubra 
             qual o melhor caminho para ter sucesso na internet. 
          </h1>
          <Form>
            <Form.Group className="mb-3 pergunta" controlId="formBasicEmail">
              <br />
              <Form.Label>DEIXE SEU MELHOR EMAIL</Form.Label>
              <Form.Control className="w-50" type="email" placeholder="" onChange={gravarEmail} />

            </Form.Group>
            <br />
            <div className="center">
              <Button variant="primary btnEnviarRespostasWhats" type="button" onClick={cadastrarParticipante}>
              QUERO DESCOBRIR MINHA PROFISSÃO DIGITAL
              </Button>
            </div>
          </Form> */}
        </Jumbotron>
      ) : (
        <>
        {!isProvaRespondida ? (
          <>
            {/* <h1 className="nota center">QUIZ</h1> */}
          {perguntas.map((pergunta, i) => {
            return (
              <>
                <Jumbotron className="painel" id={`pergunta-${pergunta.id}`} style={{ background: '#1a1a1a', display: 'none' }}>
                  <Form style={{ background: '#1a1a1a' }} onSubmit={handleSubmit}>
                  <h1 className="pergunta">{`${i + 1}) ${pergunta.pergunta}`}</h1>
                  <fieldset className="alternativasRadius">
                    <Form.Group as={Row} className="mb-3">
                        <Col sm={10}>
                          {respostas.map((resposta, i) => {
                            if (pergunta.id !== resposta.perguntas_id) {
                              return <></>;
                            }
                            return (
                              <>
                                <Form.Check
                                style={{ color: '#fff' }}
                                type="radio"
                                key={i}
                                label={`${resposta.opcao}) ${resposta.resposta}`}
                                onChange={salvarResposta}
                                name={`${pergunta.id}`}
                                value={`${resposta.id}`}
                                opcao={`${resposta.opcao}`}
                                id={`formHorizontalRadios${resposta.id}`}
                                className="alternativa" />
                              </>
                            )
                          })}
                        </Col>
                      </Form.Group>
                    </fieldset>
                  </Form>
                </Jumbotron>
              </>
              )
            })}
            <h1 className="nota center" style={{color: '#fff'}}>Descubra o caminho ideal para o seu sucesso na internet.</h1>
          </>
        ) : (
          <Jumbotron className="painel painelresultado" style={{ background: '#1a1a1a' }}> 

<img height="1" width="1" src="https://www.facebook.com/tr?id=608209829624734&ev=CompleteRegistration&noscript=1"/>


            <h1 className="pergunta center tituloResultado">TESTE CONCLUÍDO</h1>
            <h1 className="nota center">
                Parabéns! Analisei os dados do seu perfil e aqui abaixo está a <br />
                trilha digital que mais combina com você.
            </h1>

            <br />

            <h1 className="pergunta center profissao">PROFISSÃO PRIMÁRIA: </h1>
            <h1 className="pergunta center perfil">{`${perfilSelecionado}`}</h1>
        
            
            <div  className="nota center">
            { perfilSelecionado === 'INFLUENCIA'  ? (
              <>              
                 <script> window.location="https://pablomarcal.com.br/quiz-trilha-influencia/"</script>
                
              </>
             
              ) : (
                <>
                </>
            )}
            </div>
            { perfilSelecionado === 'ARTE'  ? (
              <>
               
               <script> window.location="https://pablomarcal.com.br/quiz-trilha-arte/"</script>
              </>
               
              ) : (
              <>
              </>
            )}
           
           {perfilSelecionado === 'TECNOLOGIA'  ? (
            <>
            
            <script> window.location="https://pablomarcal.com.br/quiz-trilha-tecnologia/"</script>
            </>
              ) : (
              <>
              </>
            )}
            { perfilSelecionado === 'LANCAMENTOS' ? (
              <>
               
               <script> window.location="https://pablomarcal.com.br/quiz-trilha-lancamento/"</script>
              </>
              ) : (
              <>
              </>
             )}
           { perfilSelecionado === 'CONTEUDO'  ? (
            <>
            
            <script> window.location="https://pablomarcal.com.br/quiz-trilha-conteudo/"</script>
            </>
            
              ) : (
              <>
              </>
              )}
            { perfilSelecionado === 'VENDAS' ? (
              <>
               
               <script> window.location="https://pablomarcal.com.br/quiz-trilha-vendas/"</script>
              </>
             
              ) : (
              <>
              </>
            )}

            { perfilSelecionado === 'NEGOCIOS' ? (
              <>
               
               <script> window.location="https://pablomarcal.com.br/quiz-trilha-influencia/"</script>
              </>
              ) : (
              <>
              </>
            )}


            
            
          </Jumbotron>
          )}
          </>
        )}
        <div className="centerImg">
          

        </div>

      

     
    </Container>
  );
};

export default RotaDigitalProva;
